<template>
  <div class="cms-html-editor-toolbar ui-row">
    <div class="block-format-dropdown">
      <div
        class="format-name ui-toolbar-item"
        @mousedown.prevent="dropDownIsShown = !dropDownIsShown"
      >
        <span v-if="selection.block == 'h1'">Título</span>
        <span v-else-if="selection.block == 'h2'">Subtítulo</span>
        <span v-else>Párrafo</span>
      </div>

      <div
        class="available-format-list"
        v-show="dropDownIsShown"
      >
        <p
          :class="{'--active': selection.block == 'p'}"
          @mousedown.prevent="setBlockFormat('<P>')"
        >Párrafo</p>
        <h2
          :class="{'--active': selection.block == 'h2'}"
          @mousedown.prevent="setBlockFormat('<H2>')"
        >Subtítulo</h2>
        <h1
          :class="{'--active': selection.block == 'h1'}"
          @mousedown.prevent="setBlockFormat('<H1>')"
        >Título</h1>
      </div>
    </div>

    <div class="ui-row command-list">
      <!-- mousedown.prevent previene que la seleccion pierda el foco y el comando se aplique correctamente -->
      <ui-icon
        type="button"
        v-for="command in availableCommands"
        :title="command.title"
        :key="command.cmd"
        :value="command.icon"
        :class="{'--active': selection[command.cmd]}"
        @mousedown.native.prevent="cmd(command.cmd)"
      ></ui-icon>
    </div>
  </div>
</template>

<script>
import { UiIcon } from '@/modules/ui/components';

export default {
  name: 'cms-html-editor-toolbar',
  components: { UiIcon },

  data() {
    return {
      // Información sobre la seleccion de texto actual
      selection: {
        bold: false,
        italic: false,
        underline: false,
        block: null
      },

      dropDownIsShown: false,

      availableCommands: [
        { cmd: 'bold', icon: 'g:format_bold' },
        { cmd: 'italic', icon: 'g:format_italic' },
        { cmd: 'underline', icon: 'g:format_underline' },
        {
          cmd: 'removeFormat',
          icon: 'g:format_clear',
          title: 'Limpiar formato'
        }
      ]
    };
  },

  methods: {
    cmd(command, value) {
      return document.execCommand(command, false, value);
    },

    onSelectionChange() {
      this.selection.bold = document.queryCommandState('bold');
      this.selection.italic = document.queryCommandState('italic');
      this.selection.underline = document.queryCommandState('underline');
      this.selection.block = document.queryCommandValue('formatblock');
    },

    setBlockFormat(tag) {
      this.cmd('formatblock', tag);
      this.dropDownIsShown = false;
    }
  },

  mounted() {
    this.onSelectionChange(); // run initial check
    document.addEventListener('selectionchange', this.onSelectionChange);
  },

  beforeDestroy() {
    document.removeEventListener('selectionchange', this.onSelectionChange);
  }
};
</script>

<style lang="scss">
.cms-html-editor-toolbar {
  position: relative;

  .command-list {
    margin: 0;
  }

  .block-format-dropdown {
    display: flex;
    position: relative;
    cursor: pointer;

    .format-name {
      width: 128px;
      // color: rgba(255, 255, 255, 0.8);
      // padding: 0 var(--ui-padding-horizontal);

      &::after {
        content: '▾';
        display: inline-block;
        margin-left: 1em;
      }

      &:hover {
        color: #fff;
        background-color: rgba(255, 255, 255, 0.2);
      }
    }

    .available-format-list {
      width: 128px;

      position: absolute;
      top: 100%;
      z-index: 5;

      border-radius: var(--ui-radius);
      border-top-left-radius: 0;
      border-top-right-radius: 0;

      color: rgba(255, 255, 255, 0.7);
      background-color: #333333;

      & > * {
        padding: var(--ui-padding);

        &:hover {
          color: #fff;
          background-color: rgba(255, 255, 255, 0.2);
        }
      }
    }
  }
}
</style>